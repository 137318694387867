.home-container {
  @include flex-c-se-c;
  // animation: fade-in 0.6s ease-in;
  text-align: center;
  padding-top: 10vh;
  height: 90vh;
  min-height: 100vw;
  // background-color: #FD9DA6;
  background-image: url("../Assets/Iphone_Landing_page.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (min-width:768px) {
    background-image: url("../Assets/Mobile_Landing_Background.png");
  }
  @media only screen and (min-width:1200px) {
    min-height: 100%;
    background-image: url("../Assets/Landing_Page.png");
  }
  .logo {
    width: $iphoneLogoSize;
    @media only screen and (min-width: 400px) {
      width: $iphoneLogoSize + 2rem;
    }
    @media only screen and (min-width: 768px) {
      width: $ipadLogoSize;
    }
    @media only screen and (min-width: 1200px) {
      width: $laptopLogoSize;
      padding-bottom: 1em;
    }
  }
  .title-info {
    .restaurant-name {
      font-family: "font-family: 'Bebas Neue', cursive;", sans-serif;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      font-size: $iphoneH1 + 0.8rem;
      padding-bottom: 0.5em;
      @media only screen and (min-width: 768px) {
        font-size: $ipadLogoName;
      }
      @media only screen and (min-width: 1200px) {
        font-size: $laptopLogoName;
        padding-bottom: 0.5em;
      }
    }
    .description {
      width: 100vw;
      @media only screen and (min-width: 1200px) {
        width: $laptopDescription;
      }
    }
  }

  .home-button-container {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
  }
  .menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: #f36576;
    color: white;
    font-size: $iphoneLinks - 0.5rem;
    border-radius: 0.3em;
    padding: 0.7em 0;
    width: 130px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    @media only screen and (min-width:768px) {
      font-size: $ipadLinks - 0.5rem;
      width: 200px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: $laptopLinks;
      margin-top: 1em;
    }
  }
  .contact-btn {
    @extend .menu-btn;
    color: #62000b;
    background-color: transparent;
    border: 2px solid #f36576;
  }
}
