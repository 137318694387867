$imagePrice: 23rem;
.dineInn-container {
  @include flex-c-c-c;
  // animation:fade-in 0.6s ease-in;
  justify-content: flex-start;
  text-align: center;
  height: 93vh;
  min-height: 100vw;
  padding-top: 7vh;
  // background-image: url("../Assets/Background_Gradient.png");
  background-image: linear-gradient(to bottom left,#f7a0a6, white );

  @media only screen and (min-width: 568px) {
    min-height: 120vw;
  }
  @media only screen and (min-width: 768px) {
    min-height: 120vw;
  }
  @media only screen and (min-width: 1200px) {
    @include flex-r-se-c;
    padding-top: 11.4vh;
    height: 88.4vh;
    min-height: 88.4vh;
    flex-wrap: wrap;
  }
  h1 {
    padding-bottom: 1em;
    font-size: $iphoneH1 + 0.5rem;
    @media only screen and (min-width: 768px) {
      font-size: $ipadH1;
      padding-bottom: 0;
    }
    @media only screen and (min-width: 1200px) {
      flex-basis: 100%;
      padding: .5em 0;
    }
  }
  .layout {
    @include flex-c-sa-c;
    width: 100%;
    height: 100%;
    @media only screen and ( min-width:1200px) {
      @include flex-r-c-c;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    .carousel-container {
      order: 1;
      width: 100%;
      @media only screen and (min-width: 1200px) {
        flex-basis: 50%;
        order: 0;
      }
      .carousel-image {
        width: 90vw;
        margin: auto;
        height: 12rem;
        background-size: cover;
        background-position: center bottom;
        @media only screen and (min-width: 370px) {
          height: 15rem;
        }
        @media only screen and (min-width: 768px) {
          height: 23rem;
        }
        @media only screen and (min-width: 1000px) {
          height: 35rem;
        }
        @media only screen and (min-width: 1200px) {
          width: 100%;
          margin: auto;
          height: $imagePrice;
        }
        @media only screen and (min-width: 1600px) {
          height: $imagePrice + 6rem;
        }
      }
      img {
        width: 100vw;
        object-fit: cover;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
      .carousel-btns {
        @include flex-r-c-c;
        margin-top: 0.6em;
        .img-btn {
          img {
            width: 3rem;
            margin: 0 0.6em;
            border-radius: 0.3rem;
            box-shadow: none;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
              0 3px 6px rgba(0, 0, 0, 0.23);
            @media only screen and (min-width: 768px) {
              width: 7rem;
              margin: 1em 0.5em 0 0.5em;
            }
            @media only screen and (min-width: 1200px) {
              width: 4rem;
              margin: 1em 0.5em 0 0.5em;
            }
          }
        }
      }
    }
    .buffet-prices {
      @include flex-c-c-c;
      width: 90vw;
      row-gap: 1rem;
      align-items: stretch;
      @media only screen and (min-width: 1200px) {
        @include flex-c-c-c;
        justify-content: flex-start;
        flex-basis: 35%;
        height: $imagePrice;
        margin-left: 1em;
      }
      @media only screen and (min-width: 1600px) {
        height: $imagePrice + 6rem;
      }
      .dinner {
        @include flex-c-c-c;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        width: 100%;
        height:100%;
        margin: auto;
        margin-right: 0.5em;
        background-color: white;
        justify-content: flex-start;
        height: 100%;
        @media only screen and (min-width: 1200px) {
          padding: 0;
          // height: 50%;
        }
        .meal-name {
          @include flex-c-c-c;
          font-size: $iphoneH2 + 0.5rem;
          background-color: #f6b5b9;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
          color:black;
          width: 100%;
          height: 50%;
          padding: 0.2em 0;
          @media only screen and (min-width: 370px) {
            padding: 0.8em 0;
          }
          @media only screen and (min-width: 768px) {
            font-size: $ipadH2 + 0.5rem;
            padding: 0.2em 0;
          }
          @media only screen and (min-width: 1200px) {
            font-size: $laptopH2 + 1.5rem;
          }
        }
        .meal-price {
          @include flex-c-c-c;
          height: 50%;
          padding: 0.2em 0;
          @media only screen and (min-width: 370px) {
            padding: 0.8em 0;
          }
          @media only screen and (min-width: 768px) {
            font-size: $ipadP;
          }
          @media only screen and (min-width: 1200px) {
            font-size: $laptopP + 0.1rem;
          }
        }
      }
      .dinner-hours {
        @extend .dinner;
        margin: 0.5em;
        @media only screen and (min-width: 768px) {
          margin-top: 1em;
        }
        @media only screen and (min-width: 1200px) {
          margin-top: 1em;
        }
        .dinner-content{
          width:100%;
          height: 100%;
          display:flex;
          justify-content: center;
          column-gap: 2rem;
        }
      }
    }
  }
}
