@mixin flex-r-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@mixin flex-r-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@mixin flex-r-sa-c {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
@mixin flex-c-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin flex-c-sb-c {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
@mixin flex-c-se-c {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
@mixin flex-c-sa-c {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
@mixin flex-r-r-c {
  display: flex;
  justify-content: right;
  align-items: right;
  flex-direction: row;
}
@mixin flex-r-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: right;
  flex-direction: row;
}
@mixin flex-r-se-c {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
@keyframes menuNav {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

$mainCol: #f6b5b9;
// Iphone Sizes
$iphoneLinks: 1.4rem;
$iphoneH1: 1.5rem;
$iphoneH2: 0.6rem;
$iphoneIcons: 2rem;
$iphoneP: 1rem;
$iphoneLogoSize: 9rem;
// Ipad Sizes
$ipadH1: 3.5rem;
$ipadH2: 1.5rem;
$ipadP: 1.8rem;
$ipadLinks: 2rem;
$ipadLogoName: 3.8rem;
$ipadLogoSize: 19rem;
$ipadIcons: 4.5rem;
// Laptop Sizes
$laptopH1: 3.5rem;
$laptopH2: 1.2rem;
$laptopP: 1.7rem;
$laptopLinks: 1.2rem;
$laptopLogoSize: 12rem;
$laptopLogoName: 3.5rem;
$laptopDescription: 35rem;
$laptopIcons: 6rem;
@import "Home";
@import "Menu";
@import "Footer";
@import "DineInn";
@import "Unknown";
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: inherit;
}
h1,
h2 {
  font-family: "Bebas Neue", cursive;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
p,
li,
a,
button {
  font-family: "Roboto", sans-serif;
}
button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.app-container {
  background-color: $mainCol;
  .active-page {
    border-bottom: 2px solid black;
  }
  .bars-tab {
    z-index: 1;
    position: fixed;
    @include flex-r-r-c;
    padding: 1em 1.2em;
    font-size: $iphoneLinks;
    @media only screen and (min-width:768px) {
      font-size: $ipadLinks;
    }
  }
  .mobile-nav {
    text-align: center;
    z-index: 1;
    position: fixed;
    overflow-y: scroll;
    // font-weight: bold;
    @include flex-c-c-c;
    justify-content: flex-start;
    background-color: $mainCol;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    animation: menuNav 0.5s;
    background-image: linear-gradient(to bottom left, #f7a0a6, white);

    .x-tab {
      @include flex-r-r-c;
      padding: 1em 1.2em;
      width: 100%;
      font-size: $iphoneLinks;
      @media only screen and (min-width:768px) {
        font-size: $ipadLinks;
      }
    }
    .nav-logo {
      width: 9rem;
      margin-bottom: 1em;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      border-radius: 50%;
      @media only screen and (min-width:768px) {
        width: 14rem;
      }
    }
    li {
      padding: 1em 0;
      font-size: $iphoneLinks;
      @media only screen and (min-width:768px) {
        font-size: $ipadLinks + 1.2rem;
      }
    }
  }
  .laptop-nav {
    z-index: 1;
    @include flex-r-sb-c;
    height: 11vh;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    // outline: 1px solid black;
    .nav-logo {
      width: 4rem;
      // outline: 1px solid black;
      position: fixed;
      left: 5em;
      top: .5em;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      border-radius: 50%;
    }
    .nav-links {
      @include flex-r-c-c;
      // outline: 1px solid black;
      padding: 1.5em 0;
      font-size: $laptopLinks + 0.3rem;
      background-color: $mainCol;
      width: 100vw;
      a {
        margin: 0 6em;
      }
    }
  }
}
