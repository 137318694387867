.menu-container {
  // animation:slid 0.6s ease-in;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  align-items: start;
  padding:2rem 0 5em 0;
  height: 100%;
  background-color: $mainCol;
  // background-image: url("../Assets/Background_Gradient.png");
  background-image: linear-gradient(to bottom left,#f7a0a6, white );

  @media only screen and (min-width:1200px) {
    padding-top:5rem;
  }
  // Menu Items & Special Combos & Family Dinners
  .category-title {
    padding: 0.5em 0;
    font-size: $iphoneH1 +.8rem;
    font-weight: bold;
    @media only screen and (min-width: 768px) {
      font-size: $ipadH1;
    }
    @media only screen and (min-width:1200px) {
      font-size: $laptopH1;
    }
  }
  // The Accordions
  .item-card {
    @include flex-c-c-c;
    font-weight: lighter;
    margin: 0.3em 0;
    @media only screen and (min-width: 768px) {
      margin: 0.6em 0em;
    }
  }
  .item-title {
    @include flex-r-sb-c;
    align-items: center;
    position:relative;
    flex-wrap:wrap;
    font-size: $iphoneH2;
    text-align: left;
    padding: 1.5em 1em;
    width: 90%;
    background-color: #ffffff;
    border-radius: 0.5em;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    @media only screen and (min-width: 768px) {
      font-size: $ipadH2;
      padding: 1em 1em;
    }
    @media only screen and (min-width: 1200px) {
      font-size: $laptopH2;
      padding: 1.7em 1em;
    }
    .img-icon{
      width:$iphoneIcons;
      position:absolute;
      top:50%;
      left:82%;
      transform: translate(-50%, -50%);
      @media only screen and (min-width: 768px) {
        width:$ipadIcons;
      }
      @media only screen and (min-width: 1200px) {
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:$laptopIcons;
      }
    }
    .btn-icon {
      position: relative;
      top: 2px;
      font-size: 1.6em;
    }
  }
  .item-section {
    margin: 1em 0;
    padding: 1em 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    width: 90%;
    border-radius: 0.5em;
    background-color: white;
    .items {
      @include flex-r-sb-c;
      padding: 0.5em;
      text-align: left;
      @media only screen and (min-width: 768px) {
        font-size: $ipadP;
      }
      @media only screen and (min-width: 1200px) {
        font-size: $laptopP;
      }
    }
    .comboFamily-prices {
      font-size:$iphoneP + .3rem;
      font-weight: bold;
      @media only screen and (min-width: 768px) {
        font-size: $ipadP;
      }
      @media only screen and (min-width: 1200px) {
        font-size: $laptopP + .5rem;
      }
    }
  }
}
