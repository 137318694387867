.footer-container {
  position: relative;
  .footer-top {
    @include flex-c-se-c;
    text-align: center;
    @media only screen and (min-width: 1200px) {
      @include flex-r-c-c;
      height: 100%;
    }
    .footer-title {
      position: relative;
      font-size: $iphoneH1;
      padding-bottom: 0.2rem;
      @media only screen and (min-width: 768px) {
        font-size: $ipadH1 - 0.2rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: $laptopH1 - 1rem;
      }
    }
    .contact {
      @include flex-c-c-c;
      color: white;
      justify-content: flex-start;
      padding: 2em 0 1em 0;
      // box-shadow:  3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      width: 100%;
      background-color: #f36576;
      font-size: $iphoneP + 0.2rem;
      @media only screen and (min-width: 768px) {
        font-size: $ipadP;
        height: 22rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: $laptopP - 0.2rem;
      }
    }
    .location {
      @extend .contact;
      color: white;
      background-color: #f36576;
    }
    .hours {
      @extend .contact;
      .hours1,
      .hours2, .hours3 {
      display:flex;
      justify-content: space-between;
        padding-bottom: 0.5em;
      }
    }
    .contact-icon {
      font-size: 2.3rem;
      padding-bottom: 1rem;
    }
    .location-icon{
      @extend .contact-icon;
    }
    .hours-icon{
      @extend .contact-icon;
    }
  }
  .footer-bottom {
    @include flex-c-se-c;
    align-items: center;
    color: white;
    background-color: $mainCol;
    .credits-1 {
      text-align: center;
      padding: 3em 0;
      width: 100%;
      .credit-title {
        color: black;
        font-size: $iphoneH1 - 0.2rem;
        font-weight: bold;
        padding-bottom: 0.5em;
        @media only screen and (min-width:768px) {
          font-size: $ipadH2 + 0.5rem;
        }
      }
      @media only screen and (min-width:768px) {
        font-size: $ipadP - 0.3rem;
      }
    }
    .credits-2 {
      @extend .credits-1;
      position: relative;
      padding-bottom: 6em;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      .credit {
        margin: 1em 0;
        a {
          color: rgb(33, 69, 187);
        }
      }
      .copyright {
        width: 90vw;
        position: absolute;
        bottom: 2em;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}
